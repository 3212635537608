import React from "react";
import Footer from "src/components/Footer_en";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/en/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/en/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/en/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/ultra_light/">Ultra Light</a>
                      </li>
                      <li>
                        <a href="/en/our-products/protect_cool/">
                          Protect &amp; Cool
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/instantglow/">Instant Glow</a>
                      </li>
                      <li>
                        <a href="/en/our-products/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/en/our-products/wetskin/">Wet skin</a>
                      </li>
                      <li>
                        <a href="/en/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/en/our-products/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/en/our-products/all_products/">
                          All products
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/en/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header"  href="/en/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/mountain/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/mountain/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/mountain/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/mountain_1680x405_topLarge.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/mountain_960x405_topSmall.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> MOUNTAIN
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>High altitude protection</h3>
                      <p>
                        Still using your summer sun protection in the mountains?{" "}
                        <br />
                        High altitude conditions call for special protection.
                        The PIZ BUIN<sup>®</sup> MOUNTAIN range has been
                        specially designed for high altitude conditions. All
                        products** feature an innovative formulation combining
                        advanced UVA/UVB sun filters and an extract of Edelweiss
                        that helps to protect your skin from the sun and
                        long-term skin damage. Enriched with a Cold Shield
                        Complex, all products** provide long-lasting
                        moisturisation, protecting the skin from extreme cold
                        and wind. PIZ BUIN<sup>®</sup> MOUNTAIN offers special*
                        protection for high altitude sun, cold and wind
                        conditions.
                      </p>
                      <p className="footNote">
                        *Designed to protect from sun, cold and wind, ** Except
                        for the lipstick
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="mountain-suncream" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/17498ecc1829bc337a6b29c0500fe814_f27.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>MOUNTAIN SUNCREAM</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          This fast absorbing*, sweat and water resistant face
                          cream is specifically designed to protect your skin
                          from unforgiving, high-altitude sun, cold and wind
                          exposure. The ultra-flat, ergonomic profile of the
                          tube fits easily in your pocket – always in reach, but
                          never in your way – so you can do what you came to do…
                          enjoy all the snow and the sun.
                          <br />
                          <a href="/en/know_the_sun/#mountain">
                            Why does the skin need special care in high altitude
                            conditions?
                          </a>
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or toweling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreen. Keep babies and children out of direct
                          sunlight.
                        </p>
                        <p>
                          <span className="footNote">
                            *Except for the cream SPF50+
                          </span>
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ADVANCED UVA/UVB SUN FILTER SYSTEM
                                <div className="textBlock bottom">
                                  <p>
                                    As suncare specialists, we use innovative
                                    technologies to make sure you achieve the
                                    right balance of colour and protection
                                    whenever you enjoy the sun.
                                  </p>
                                  <p>
                                    Each of the advanced UVA/UVB sun filter
                                    systems is a powerful sun protection
                                    technology developed by PIZ BUIN<sup>®</sup>
                                    . They offer effective photostable,
                                    broad-spectrum UVA and UVB protection, and
                                    help achieve that PIZ BUIN<sup>®</sup>{" "}
                                    sunscreens comply with the latest European
                                    Commission recommendation on sunscreen
                                    products.
                                  </p>
                                  <p>
                                    All of the advanced UVA/UVB sun filter
                                    systems help to protect from UVA and UVB
                                    radiation.
                                  </p>
                                  <p>
                                    UVB rays - "burning rays" - harm the skin’s
                                    surface and are the main cause of sunburn.
                                  </p>
                                  <p>
                                    UVA rays – "aeging rays" - penetrate deeper
                                    and damage collagen and elastin, which can
                                    result in premature skin ageing and cancer.
                                  </p>
                                  <p>
                                    The advanced UVA/UVB sun filter systems
                                    contain both UVA and UVB filters to help
                                    protect against the sun and long term skin
                                    damage while also protecting against the
                                    more serious effects of UVA radiation. They
                                    cover most of the UVA / UVB spectrum,
                                    offering broadband protection for your skin.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                GLYCERINE
                                <div className="textBlock bottom">
                                  <p>
                                    Glycerine attracts water and holds it in the
                                    skin to help maintain a good hydration
                                    level.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                COLD SHIELD COMPLEX
                                <div className="textBlock bottom">
                                  <p>
                                    The Cold Shield Complex helps to provide
                                    long lasting moisturisation, protecting the
                                    skin from extreme wind and cold. It
                                    contains: Shea Butter, Trehalose and
                                    Glycerin.{" "}
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4a08f11c5a84c8890abab5c8850f2fe6_f39.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                SHEA BUTTER
                                <div className="textBlock bottom">
                                  <p>
                                    Shea butter is derived from the shea nut
                                    tree, which originally grows in Western
                                    Africa. It has been used for centuries to
                                    moisturise and protect skin from the effects
                                    of sun, wind, heat and salt water. It is
                                    also widely used in cosmetics because of its
                                    nourishing, moisturising and protective
                                    properties.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4e8fd1f288f22f5c0578667ed26ebe09_f46.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                TREHALOSE
                                <div className="textBlock bottom">
                                  <p>
                                    Trehalose is a naturally occurring
                                    disaccharide and exists in a wide variety of
                                    daily foods and animals. It is considered to
                                    protect living organisms from hostile
                                    environmental conditions like extreme cold.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/12e0f2e932194e93854b001526d89faa_f48.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                EDELWEISS
                                <div className="textBlock bottom">
                                  <p>
                                    Known as the 'White Star of the Glacier', it
                                    has a strong natural defence system that
                                    enables it to survive the intense sunlight
                                    and extreme cold and wind common to alpine
                                    environments. The Edelweiss extract helps to
                                    neutralise free radicals generated in the
                                    skin*, helping it maintain its defences
                                    against UV radiation.{" "}
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *In Vitro Test
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/76186a208bd7eda9cefbe98fc7c193b8_f41.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="mountain-suncream-lipstick" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/737b127106848abb7ee2db5b708654a2_f24.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>MOUNTAIN SUNCREAM + LIPSTICK</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          This fast absorbing*, sweat and water resistant face
                          cream is specifically designed to protect your exposed
                          skin from unforgiving high altitude sun, cold and
                          wind. The lipstick protects your lips from the harmful
                          effects of UVA/UVB exposure and provides intense
                          moisturisation. The convenient sun cream + lipstick
                          combi is designed with winter sports in mind. It fits
                          easily into your pocket and can be applied whenever
                          you need it.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or toweling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreen. Keep babies and children out of direct
                          sunlight.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ADVANCED UVA/UVB SUN FILTER SYSTEM
                                <div className="textBlock bottom">
                                  <p>
                                    As suncare specialists, we use innovative
                                    technologies to make sure you achieve the
                                    right balance of colour and protection
                                    whenever you enjoy the sun.
                                  </p>
                                  <p>
                                    Each of the advanced UVA/UVB sun filter
                                    systems is a powerful sun protection
                                    technology developed by PIZ BUIN<sup>®</sup>
                                    . They offer effective photostable,
                                    broad-spectrum UVA and UVB protection, and
                                    help achieve that PIZ BUIN<sup>®</sup>{" "}
                                    sunscreens comply with the latest European
                                    Commission recommendation on sunscreen
                                    products.
                                  </p>
                                  <p>
                                    All of the advanced UVA/UVB sun filter
                                    systems help to protect from UVA and UVB
                                    radiation.
                                  </p>
                                  <p>
                                    UVB rays - "burning rays" - harm the skin’s
                                    surface and are the main cause of sunburn.
                                  </p>
                                  <p>
                                    UVA rays – "aeging rays" - penetrate deeper
                                    and damage collagen and elastin, which can
                                    result in premature skin ageing and cancer.
                                  </p>
                                  <p>
                                    The advanced UVA/UVB sun filter systems
                                    contain both UVA and UVB filters to help
                                    protect against the sun and long term skin
                                    damage while also protecting against the
                                    more serious effects of UVA radiation. They
                                    cover most of the UVA / UVB spectrum,
                                    offering broadband protection for your skin.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                GLYCERINE
                                <div className="textBlock bottom">
                                  <p>
                                    Glycerine attracts water and holds it in the
                                    skin to help maintain a good hydration
                                    level.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                COLD SHIELD COMPLEX
                                <div className="textBlock bottom">
                                  <p>
                                    The Cold Shield Complex helps to provide
                                    long lasting moisturisation, protecting the
                                    skin from extreme wind and cold. It
                                    contains: Shea Butter, Trehalose and
                                    Glycerin.{" "}
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4a08f11c5a84c8890abab5c8850f2fe6_f39.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                SHEA BUTTER
                                <div className="textBlock bottom">
                                  <p>
                                    Shea butter is derived from the shea nut
                                    tree, which originally grows in Western
                                    Africa. It has been used for centuries to
                                    moisturise and protect skin from the effects
                                    of sun, wind, heat and salt water. It is
                                    also widely used in cosmetics because of its
                                    nourishing, moisturising and protective
                                    properties.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4e8fd1f288f22f5c0578667ed26ebe09_f46.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                TREHALOSE
                                <div className="textBlock bottom">
                                  <p>
                                    Trehalose is a naturally occurring
                                    disaccharide and exists in a wide variety of
                                    daily foods and animals. It is considered to
                                    protect living organisms from hostile
                                    environmental conditions like extreme cold.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/12e0f2e932194e93854b001526d89faa_f48.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                EDELWEISS
                                <div className="textBlock bottom">
                                  <p>
                                    Known as the 'White Star of the Glacier', it
                                    has a strong natural defence system that
                                    enables it to survive the intense sunlight
                                    and extreme cold and wind common to alpine
                                    environments. The Edelweiss extract helps to
                                    neutralise free radicals generated in the
                                    skin*, helping it maintain its defences
                                    against UV radiation.{" "}
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *In Vitro Test
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/76186a208bd7eda9cefbe98fc7c193b8_f41.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="mountain-lipstick" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/bec27ab6d2fee25d141e210d5d28a8fc_f26.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>MOUNTAIN LIPSTICK</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          The PIZ BUIN<sup>®</sup> MOUNTAIN Lipstick is
                          especially designed for high altitude sun, cold and
                          wind conditions. It helps to protect your lips from
                          the sun and provides intense moisturisation.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or toweling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreen. Keep babies and children out of direct
                          sunlight.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ADVANCED UVA/UVB SUN FILTER SYSTEM
                                <div className="textBlock bottom">
                                  <p>
                                    As suncare specialists, we use innovative
                                    technologies to make sure you achieve the
                                    right balance of colour and protection
                                    whenever you enjoy the sun.
                                  </p>
                                  <p>
                                    Each of the advanced UVA/UVB sun filter
                                    systems is a powerful sun protection
                                    technology developed by PIZ BUIN<sup>®</sup>
                                    . They offer effective photostable,
                                    broad-spectrum UVA and UVB protection, and
                                    help achieve that PIZ BUIN<sup>®</sup>{" "}
                                    sunscreens comply with the latest European
                                    Commission recommendation on sunscreen
                                    products.
                                  </p>
                                  <p>
                                    All of the advanced UVA/UVB sun filter
                                    systems help to protect from UVA and UVB
                                    radiation.
                                  </p>
                                  <p>
                                    UVB rays - "burning rays" - harm the skin’s
                                    surface and are the main cause of sunburn.
                                  </p>
                                  <p>
                                    UVA rays – "aeging rays" - penetrate deeper
                                    and damage collagen and elastin, which can
                                    result in premature skin ageing and cancer.
                                  </p>
                                  <p>
                                    The advanced UVA/UVB sun filter systems
                                    contain both UVA and UVB filters to help
                                    protect against the sun and long term skin
                                    damage while also protecting against the
                                    more serious effects of UVA radiation. They
                                    cover most of the UVA / UVB spectrum,
                                    offering broadband protection for your skin.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                GLYCERINE
                                <div className="textBlock bottom">
                                  <p>
                                    Glycerine attracts water and holds it in the
                                    skin to help maintain a good hydration
                                    level.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="glacier-cream" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/bb275870e7811876ad77867df1360175_f25.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>GLACIER CREAM</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          The Glacier Cream is the PIZ BUIN<sup>®</sup> brand’s
                          latest innovation in winter sun care technology. While
                          the unique PIZ BUIN<sup>®</sup> water-free formula
                          forms an intensely moisturising barrier to protect
                          from cold and wind, the advanced UVA/UVB sun filter
                          system helps to protect from intense high altitude
                          sun. To ensure performance even under very harsh
                          conditions, we tested our PIZ BUIN<sup>®</sup> Glacier
                          Cream with consumers in high altitude conditions in
                          one of the coldest countries in the world… Norway*:
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            <strong className="orange">84%</strong> confirm:
                          </p>
                          <ul>
                            <li>
                              Effective protection from high altitude sun, cold
                              and wind conditions
                            </li>
                          </ul>
                          <p>
                            <strong>
                              <span className="orange">96%</span>
                            </strong>{" "}
                            confirm:
                          </p>
                          <ul>
                            <li>
                              A protective barrier that protects from winter
                              dryness
                            </li>
                          </ul>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Read more
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or toweling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreen. Keep babies and children out of direct
                          sunlight.
                        </p>
                        <p className="footNote">
                          *Self-assessment results obtained on the Glacier Cream
                          25 subjects, 1 week, at least one application per day
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ADVANCED UVA/UVB SUN FILTER SYSTEM
                                <div className="textBlock bottom">
                                  <p>
                                    As suncare specialists, we use innovative
                                    technologies to make sure you achieve the
                                    right balance of colour and protection
                                    whenever you enjoy the sun.
                                  </p>
                                  <p>
                                    Each of the advanced UVA/UVB sun filter
                                    systems is a powerful sun protection
                                    technology developed by PIZ BUIN<sup>®</sup>
                                    . They offer effective photostable,
                                    broad-spectrum UVA and UVB protection, and
                                    help achieve that PIZ BUIN<sup>®</sup>{" "}
                                    sunscreens comply with the latest European
                                    Commission recommendation on sunscreen
                                    products.
                                  </p>
                                  <p>
                                    All of the advanced UVA/UVB sun filter
                                    systems help to protect from UVA and UVB
                                    radiation.
                                  </p>
                                  <p>
                                    UVB rays - "burning rays" - harm the skin’s
                                    surface and are the main cause of sunburn.
                                  </p>
                                  <p>
                                    UVA rays – "aeging rays" - penetrate deeper
                                    and damage collagen and elastin, which can
                                    result in premature skin ageing and cancer.
                                  </p>
                                  <p>
                                    The advanced UVA/UVB sun filter systems
                                    contain both UVA and UVB filters to help
                                    protect against the sun and long term skin
                                    damage while also protecting against the
                                    more serious effects of UVA radiation. They
                                    cover most of the UVA / UVB spectrum,
                                    offering broadband protection for your skin.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                GLYCERINE
                                <div className="textBlock bottom">
                                  <p>
                                    Glycerine attracts water and holds it in the
                                    skin to help maintain a good hydration
                                    level.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/en/our-products/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/ultra_light/" target="_self">
                      Ultra Light
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/protect_cool/" target="_self">
                      Protect &amp; Cool
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/wetskin/" target="_self">
                      Wet skin
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/en/our-products/mountain/"
                      target="_self"
                    >
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/aftersun/" target="_self">
                      After sun
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/all_products/" target="_self">
                      All products
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Read More');\n} else {\nbox.slideDown();\n$(this).text('Read Less');\n}\n});\n});\n"
          }}
        />
       <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
